/**
 * Extrai a extensão do arquivo baseado na URL.
 *
 * @param {string} url Url para download do arquivo
 * @returns {string} Extensão extraída
 * @access public
 */
export const extractExtensionFromUrl = (url) => {
  const [fileUrlWithoutParameters] = url.split('?');
  const [ext] = fileUrlWithoutParameters.split('.').slice(-1);
  return ext;
};

import React from 'react';

import * as S from './styles';
import { extractExtensionFromUrl } from '../../../../utils/extractExtensionFromUrl';

const MessageAudio = ({ message }) => {
  return (
    <S.CustomAudio
      controls
      title={extractExtensionFromUrl(message?.audio?.fileUrl)}
    >
      <source src={message?.audio?.fileUrl} type="audio/mp3" />
      <track
        src="captions_en.vtt"
        kind="captions"
        srcLang="en"
        label="english_captions"
      />
    </S.CustomAudio>
  );
};

export default MessageAudio;

import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  formatVariables,
  treatMediaToLocalStorage,
} from '../utils/formatValues';

import {
  getNewMessages,
  getNewHistoricMessages,
  createStatus,
  formatMessage,
  verifyMessageAlreadyExists,
  formatNewMessage,
} from '../utils/messengerUtils';

import LocalStorageUtils from '../utils/localStorageUtils';
import {
  importScriptRybena,
  closePlayer,
  stopTranslate,
} from '../utils/rybena';
import { ChatContext } from './ChatContext';
import { SettingsContext } from './SettingsContext';
import { getBotData } from '../services/getBotData';
import {
  setMessages,
  cleanMessages,
  messages,
  setNewArrayMessages,
} from './messagesMemory';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const API_URL = process.env.REACT_APP_API_URL;
const channel = 'webchat';

const MessengerProvider = ({ children }) => {
  const { bot, customSettings, setIsNotFound } = useContext(SettingsContext);
  const { _id: botId } = bot;
  const [hasConnection, setHasConnection] = useState(false);
  const [hasToSubscribe, setHasToSubscribe] = useState(false);
  const { variables, webchatType } = customSettings;
  const { setOnLocalStorage, removeOnLocalStorage, getOnLocalStorage } =
    LocalStorageUtils();
  const [isReconnecting, setIsReconnecting] = useState(false);
  const {
    currentBotId,
    setCurrentBotId,
    clientRef,
    socketId,
    setSocketId,
    sessionId,

    setHistoricMessages,
    setUniqueSessionIds,
    setSessionMessages,
    setImgSrc,
    setImgTitle,
    setFullScreen,
    fullScreen,
    setSessionId,
    isMute,
    widgetIsFocused,
    updateScrollToTop,
    playNotificationSound,
    lastSessionId,
    setLastSessionId,
    conversationIsStarted,
    pageIsOpen,
    setHandleResetTranslate,
    webchatIsStarted,
    timeoutId,
    setInterruptMessage,
    setTranslateMessages,
    setClientMessge,
    setTimeoutId,
    setResetChat,
    uniqueSessionIds,
    setHasHistoryToLoad,
    setHasIdentifierClient,
  } = useContext(ChatContext);

  const connectSocket = (isDisconnect = false) => {
    if (clientRef.current) {
      clientRef.current.onclose = null;
      clientRef.current.close();
      clientRef.current = null;
      setSocketId(null);
    }

    const client = new WebSocket(SOCKET_URL);
    clientRef.current = client;

    client.addEventListener('open', () => {
      const sessionId = clientRef?.sessionId || getOnLocalStorage('session-id');
      if (isDisconnect && !sessionId) return;

      client.send(
        JSON.stringify({
          action: 'link',
          data: { sessionId, botId },
        })
      );
      setIsReconnecting(true);
    });

    client.addEventListener('error', (evt) => {
      console.log('error', evt);
    });

    client.onclose = () => {
      console.log('closed connection');
      connectSocket(true);
    };
  };

  const resetChatWithoutConnect = (botId, timeoutId) => {
    setCurrentBotId(botId);
    setSessionId('');
    removeOnLocalStorage(['session-id', 'session-messages', 'session-params']);
    setSessionMessages([]);
    cleanMessages();
    setHistoricMessages([]);
    setUniqueSessionIds([]);
    setHasHistoryToLoad(true);
    setHasIdentifierClient(false);
    setHasConnection(false);
    clearTimeout(timeoutId);
    setTimeoutId(null);
    setIsNotFound(true);
  };

  const handleReset = () => {
    cleanMessages();
    setHistoricMessages([]);
    setUniqueSessionIds([]);
    setSessionMessages([]);
    setHasHistoryToLoad(true);
    setHasIdentifierClient(false);
    setHasConnection(false);
    stopTranslate();
    setResetChat(true);
    closePlayer('reset', setHandleResetTranslate);

    removeOnLocalStorage(['session-id', 'session-messages', 'session-params']);
    setCurrentBotId(botId);
    setSessionId('');
    clearTimeout(timeoutId);
    setTimeoutId(null);
    connectSocket();
  };

  const handleStatusMessage = (message, status) => {
    const currentSessionId =
      sessionId || getOnLocalStorage('session-id') || lastSessionId;
    if (!clientRef.current) return;

    fetch(`${API_URL}/status`, {
      method: 'POST',
      body: JSON.stringify({
        sessionId: currentSessionId,
        botId,
        status,
        messageId: message?.id,
      }),
    });
  };

  const handleExistingSession = (undeliveredBotMessages) => {
    const statusMessage = createStatus(
      webchatType,
      widgetIsFocused,
      pageIsOpen
    );
    let newMessages = [];
    if (webchatType === 'fullscreen') {
      newMessages = messages;
    } else {
      const messagesOnStorage = getOnLocalStorage('session-messages');
      newMessages = messagesOnStorage ? JSON.parse(messagesOnStorage) : [];
    }

    newMessages = [
      ...newMessages,
      ...(undeliveredBotMessages
        ?.map((undeliveredMessage) => {
          const messageAlreadyExists = undeliveredMessage?.id
            ? verifyMessageAlreadyExists(newMessages, undeliveredMessage?.id)
            : false;
          if (!messageAlreadyExists) {
            handleStatusMessage(undeliveredMessage, statusMessage);
            return formatMessage(undeliveredMessage, 'bot', statusMessage);
          }
          return undefined;
        })
        .filter((message) => message !== undefined) || []),
    ];

    setNewArrayMessages(newMessages);
    setSessionMessages(newMessages);
    setOnLocalStorage('session-messages', JSON.stringify(newMessages));
    updateScrollToTop();
  };

  const subscribe = async (data) => {
    const onlyGetUndeliveryMessages = data?.onlyGetUndeliveryMessages;
    if (!socketId) {
      return connectSocket();
    }

    const currentSessionId =
      webchatType === 'fullscreen'
        ? sessionId
        : getOnLocalStorage('session-id');

    if (currentSessionId) {
      setSessionId(currentSessionId);
    }
    if (onlyGetUndeliveryMessages && !currentSessionId) return;
    const botData = await getBotData({ botId });
    if (botData && botData.apiRybena) {
      importScriptRybena(botData);
    }
    if (botData && botData?.channel?.isActive) {
      if (botId) {
        const data = await fetch(`${API_URL}/subscribe`, {
          method: 'POST',
          body: JSON.stringify({
            socketId,
            sessionId: currentSessionId,
            botId,
            isPreview: false,
            channel,
            parameters: JSON.stringify(formatVariables(variables)),
            onlyGetUndeliveryMessages,
          }),
        })
          .then((response) => response.json())
          .catch((error) => console.log('Error to subscribe: ', error));

        const newSessionId = data?.sessionId;
        const isNewSession = data?.isNewSession;
        const undeliveredBotMessages = data?.undeliveredBotMessages || [];

        if (!newSessionId && !onlyGetUndeliveryMessages) {
          return handleReset();
        }

        setSessionId(newSessionId);

        if (isNewSession) {
          removeOnLocalStorage([
            'session-id',
            'session-messages',
            'session-params',
          ]);
        } else {
          handleExistingSession(undeliveredBotMessages);
        }

        setSessionId(newSessionId);
        setOnLocalStorage('session-id', newSessionId);
      }
    } else {
      resetChatWithoutConnect();
    }
  };

  const onClientMessage = async ({ data: strData }) => {
    const { action, data = {} } = JSON.parse(strData);
    if (action === 'link') {
      const { socketId } = data;
      setSocketId(socketId);
    } else if (action === 'end_conversation') {
      if (!data?.isTransfer) {
        if (currentBotId) {
          setCurrentBotId(botId);
        }
        setLastSessionId(sessionId);
        setSessionId(null);
        removeOnLocalStorage([
          'session-id',
          'session-messages',
          'session-params',
        ]);
        setHasConnection(false);
        setSessionMessages([]);
      }
    } else if (action === 'message') {
      const statusMessage = createStatus(
        webchatType,
        widgetIsFocused,
        pageIsOpen
      );

      setMessages(formatNewMessage(data, data?.from || 'bot', statusMessage));

      if (data.type !== 'TYPING') {
        handleStatusMessage(data, statusMessage);
        playNotificationSound('receiving', isMute);
      }

      setSessionMessages((prevMessages) => {
        const newMessages = getNewMessages(
          prevMessages,
          data,
          data?.from || 'bot'
        );
        if (data.type !== 'TYPING') {
          setOnLocalStorage('session-messages', JSON.stringify(newMessages));
        }
        return newMessages;
      });

      updateScrollToTop();
    }

    if (action === 'historicMessage') {
      data.forEach((el) => {
        setHistoricMessages((prevMessages) => {
          return getNewHistoricMessages(
            prevMessages,
            el,
            el?.from || 'bot',
            ''
          );
        });
      });
    }

    if (action === 'identifierClient') {
      if (data.sessionId) {
        uniqueSessionIds.push(data.sessionId);
        setHasIdentifierClient(true);
      }
    }
  };

  const handleSubmitMessage = async ({
    message,
    type,
    ext,
    properties = {},
  }) => {
    const messageTreated = message.trim();
    const dataToMessage = {
      message: messageTreated,
      type,
      ...properties,
    };
    let hasToSubscribe = false;

    setClientMessge(true);
    setInterruptMessage(true);

    const translateMessages = messages.map((message) => {
      return {
        ...message,
        isTranslate: true,
      };
    });

    setNewArrayMessages(translateMessages);
    stopTranslate();

    setMessages(formatNewMessage(dataToMessage, 'user'));

    setSessionMessages((prevMessages) => {
      const newMessages = getNewMessages(
        prevMessages,
        treatMediaToLocalStorage(dataToMessage),
        'user'
      );
      setOnLocalStorage('session-messages', JSON.stringify(newMessages));
      return newMessages;
    });

    playNotificationSound('sending', isMute);

    if (!sessionId) {
      subscribe();
    } else {
      stopTranslate();
      const isMedia = type !== 'TEXT';
      await fetch(`${API_URL}/message`, {
        method: 'POST',
        body: JSON.stringify({
          sessionId,
          botChannel: channel,
          botId: currentBotId || botId,
          message: messageTreated,
          isMedia,
          ext,
          isPreview: false,
          socketId,
        }),
      })
        .then(async (data) => {
          const response = await data.json();
          if (response) {
            if (response.isNewSession) {
              removeOnLocalStorage([
                'session-id',
                'session-messages',
                'session-params',
              ]);
              setSessionId(response.sessionId);
              setOnLocalStorage('session-id', response.sessionId);
            }
            if (response.hasUndeliveredMessages) {
              hasToSubscribe = true;
            }
          }
        })
        .catch((error) => console.log('SessionId not found: ', error));
    }

    setTranslateMessages(translateMessages);
    updateScrollToTop();
    if (hasToSubscribe) {
      setHasToSubscribe(true);
    }
  };

  const handleCarouselButtonClick = (clickInfo) => {
    const { clickedButton, clickedCard } = clickInfo;
    const { destination } = clickedButton;
    const { type, value } = destination;

    if (type === 'url') {
      window.open(value, '_blank');
    }

    if (type === 'phone') {
      window.open(`tel:${value}`, '_blank');
    }

    if (!sessionId) {
      subscribe();
    } else {
      fetch(`${API_URL}/action`, {
        method: 'POST',
        body: JSON.stringify({
          sessionId,
          botId: currentBotId || botId,
          action: { type: 'CAROUSEL', data: { clickedButton, clickedCard } },
        }),
      }).catch((error) => console.log('Error to execute action: ', error));
    }
  };

  const handleQuickAccessClick = ({ itemId, title }) => {
    if (!sessionId) {
      subscribe();
    } else {
      fetch(`${API_URL}/quick-access`, {
        method: 'POST',
        body: JSON.stringify({
          sessionId,
          botId: currentBotId || botId,
          itemId,
          name: title,
          isPreview: false,
        }),
      }).catch((error) => console.log('Error to quick-access: ', error));
    }
  };

  const handleClickImage = (src, title) => {
    setImgSrc(src);
    setImgTitle(title);
    setFullScreen(!fullScreen);
  };

  const updateStatusMessages = (messages) => {
    return messages.map((message) => {
      if (message.status === 'delivered') {
        handleStatusMessage(message, 'read');
        return {
          ...message,
          status: 'read',
        };
      }
      return message;
    });
  };

  const handleLoadPreviousMessages = async () => {
    await fetch(`${API_URL}/historicMessage`, {
      method: 'POST',
      body: JSON.stringify({
        uniqueSessionIds,
        botId: currentBotId || botId,
        currentSession: sessionId,
        socketId,
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          setHasHistoryToLoad(false);
        }
      })
      .catch((error) =>
        console.log('Error retrieving historic messages: ', error)
      );
  };

  useEffect(() => {
    if (clientRef.current) {
      setIsReconnecting(false);
      setSessionId(sessionId);
      clientRef.current.onmessage = onClientMessage;
    }
  }, [
    socketId,
    clientRef?.current,
    isMute,
    currentBotId,
    sessionId,
    widgetIsFocused,
    conversationIsStarted,
  ]);

  useEffect(() => {
    if (hasToSubscribe) {
      subscribe({ onlyGetUndeliveryMessages: true });
      setHasToSubscribe(false);
    }
  }, [hasToSubscribe]);

  useEffect(() => {
    if (pageIsOpen && botId) {
      subscribe({ onlyGetUndeliveryMessages: true });
    }
  }, [pageIsOpen]);

  useEffect(() => {
    if (socketId && !hasConnection) {
      setHasConnection(true);
      subscribe();
    }
  }, [socketId]);

  useEffect(() => {
    if (clientRef) {
      clientRef.sessionId = sessionId;
    }
  }, [sessionId]);

  /* UseEffect responsável por enviar uma ação ao API Gateway a cada 9 minutos para garantir que a conexão do Socket persista. */
  useEffect(() => {
    if (clientRef?.current) {
      const sendKeepingConnection = () => {
        clientRef.current.send(
          JSON.stringify({
            action: 'message',
            data: {
              action: 'keepingConnection',
              sessionId,
            },
          })
        );
      };
      const intervalId = setInterval(sendKeepingConnection, 9 * 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [clientRef?.current]);

  // UseEffect responsável por fazer o subscribe ao abrir o widget
  useEffect(() => {
    if (webchatIsStarted && !hasConnection) {
      subscribe();
    }
  }, [webchatIsStarted]);

  return (
    <MessengerContext.Provider
      value={{
        updateScrollToTop,
        handleClickImage,
        handleQuickAccessClick,
        handleCarouselButtonClick,
        handleLoadPreviousMessages,
        handleSubmitMessage,
        handleReset,
        getNewMessages,
        getNewHistoricMessages,
        playNotificationSound,
        handleStatusMessage,
        subscribe,
        updateStatusMessages,
        connectSocket,
      }}
    >
      {children}
    </MessengerContext.Provider>
  );
};

export default MessengerProvider;
export const MessengerContext = createContext();
export const useMessengerContext = () => useContext(MessengerContext);
